<template>
  <div class="mt-5">
    <!-- Filter -->
    <div class="row align-items-center justify-content-between mt-n3">
      <div class="col-md-12 d-flex justify-content-end">
        <div class="d-flex justify-content-end align-items-center mt-5">
          <span class="text-medium mr-2"
            >Periode : {{ monthName + " " + year }}</span
          >
          <button
            class="btn btn-primary mr-1"
            @click="$bvModal.show('modal-action-doctor')"
          >
            Expor <i class="fas fa-print px-0 ml-1"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered mt-3" v-if="nullMessage ==  false">
        <tbody>
          <tr
            v-for="(data, index) in itemData.length"
            :key="index"
            v-bind:style="{ 'background-color': styleBind(index) }"
          >
            <td
              v-for="(indexData, count) in itemData[index].length"
              :key="count"
              class="text-medium"
              style="white-space: nowrap"
            >
              <span v-html="itemData[index][indexData - 1]"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row justify-content-center" v-if="nullMessage == true">
      <span>Data Tidak Ditemukan</span>
    </div>

    <div class="row">
      <div class="col-12 mt-6">
        <span class="text-info"
          ><i class="fas fa-info-circle mr-1 text-info"></i> Catatan</span
        >
        <ul class="ml-6">
          <li>
            <span>Op = Operator</span>
          </li>
          <li>
            <span>An = Anestesi</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-action-doctor"
      size="xl"
      title="Ekspor Rekap Tindakan Medis Perbulan"
      hide-footer
      body-class="pt-0"
    >
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-start align-items-center mt-5">
            <button
              class="btn btn-primary mr-1"
              v-b-tooltip.hover
              title="Print"
              @click="btnPrintOnClick"
            >
              <i class="fas fa-print px-0"></i>
            </button>
            <button
              class="btn btn-info mr-1"
              v-b-tooltip.hover
              title="Download Pdf"
              @click="btnDownloadOnClick"
            >
              <i class="fas fa-file-pdf px-0"></i>
            </button>
            <button
              class="btn btn-warning mr-1"
              v-b-tooltip.hover
              title="Download Excel"
              @click="btnExcelOnClick"
            >
              <i class="fas fa-file-excel px-0"></i>
            </button>
          </div>

          <table class="table table-bordered mt-3" v-if="nullMessage == false">
            <tbody>
              <tr
                v-for="(data, index) in itemData.length"
                :key="index"
                v-bind:style="{ 'background-color': styleBind(index) }"
              >
                <td
                  v-for="(indexData, count) in itemData[index].length"
                  :key="count"
                  class="text-medium"
                  style="max-width: 80px"
                >
                  <span v-html="itemData[index][indexData - 1]"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- end Modal -->

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Rumah Sakit'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div id="print" class="row">
          <div
            class="col-12 d-flex justify-content-center mt-4"
            v-if="nullMessage == false"
          >
            <span
              >Rekap Tindakan Medis Dokter {{ setting.clinic_name }} (Periode
              {{ month }} {{ year }})</span
            >
          </div>
          <div class="col-12">
            <table class="table table-bordered mt-3" style="max-width: 2480px">
              <tbody>
                <tr
                  v-for="(data, index) in itemData.length"
                  :key="index"
                  v-bind:style="{ 'background-color': styleBind(index) }"
                >
                  <td
                    v-for="(indexData, count) in itemData[index].length"
                    :key="count"
                    class="text-medium"
                    style="max-width: 80px"
                  >
                    <span
                      v-html="itemData[index][indexData - 1]"
                      style="font-size: 60%"
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- end PDF -->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import VueHtml2pdf from "vue-html2pdf";
import XLSX from "xlsx";

export default {
  props: {
    month: String,
    monthName: String,
    year: String,
  },
  components: {
    XLSX,
    VueHtml2pdf,
  },

  data() {
    return {
      nullMessage: true,
      currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      currentMonth: "",
      // year: "",
      // month: "",
      // Filter
      date: "",
      menu: false,
      modal1: false,
      filter: {
        name: "",
      },
      sumData: {
        service: "",
        employee: "",
        assistant: "",
        omploop: "",
        nurse_action: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi Dokter",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah Sakit",
          sortable: true,
          thClass: "text-truncate",
          thStyle: { maxWidth: "90px", overflowX: "auto" },
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      itemData: [],
      itemExcel: [],
      // User Access
      btn: false,
    };
  },

  methods: {
    styleBind(id) {
      if (id == 0) {
        return "#e8e8e8";
      }
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    chooseItem(value) {
      this.$emit("chooseItem", value);
    },

    async getSetting() {
      this.setting = await module.get(`settings/${1}`);
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.itemExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(
        wb,
        `Laporan Rekap Tindakan Medis Perawat ${setting.clinic_name} (Periode ${
          this.month + " " + this.year
        }).xlsx`
      );
    },

    async pagination() {
      this.nullMessage = false;

      let filterParams = `?month=${this.month}&year=${this.year}`;

      let response = await module.get(
        "medical-action-monthly",
        `${filterParams}`
      );

      this.items = response;

      console.log(this.items,"ITEMSSSS");

      const actions = response;

      let newData = {};
      let newStaff = {};
      let result = [];

      // pengumpulan data
      actions.forEach((action, index) => {
        action.action_type_display.forEach((actionDisplay) => {
          if (!newData[actionDisplay.name]) {
            newData[actionDisplay.name] = {};
          }

          action.operator_display.forEach((Op) => {
            if (!newData[actionDisplay.name][Op.name]) {
              newData[actionDisplay.name][Op.name] = {
                Op: 0,
                An: 0,
              };
            }

            newStaff[Op.id] = Op;
            newData[actionDisplay.name][Op.name].Op += 1;
          });

          action.anesthetist_display.forEach((An) => {
            if (!newData[actionDisplay.name][An.name]) {
              newData[actionDisplay.name][An.name] = {
                Op: 0,
                An: 0,
              };
            }

            newStaff[An.id] = An;
            newData[actionDisplay.name][An.name].An += 1;
          });

          // action.nurse_action_display.forEach((P) => {
          //   if (!newData[actionDisplay.name][P.name]) {
          //     newData[actionDisplay.name][P.name] = {
          //       A: 0,
          //       O: 0,
          //       P: 0
          //     }
          //   }

          //   newStaff[P.id] = P
          //   newData[actionDisplay.name][P.name].P += 1
          // })
        });
      });

      // bagian nampilin data ke table
      let names = Object.values(newStaff).map((staff) => staff.name);
      names.forEach((name, index) => {
        let splitRes = name.split("-");
        let f;
        let nameRes = "";
        for (f = 0; f < splitRes.length - 1; f++) {
          nameRes = nameRes + "" + splitRes[f];
        }

        names[index] = nameRes;
      });
      result.push(["Tindakan", ...names]);

      Object.keys(newData).forEach((key) => {
        let newRow = [key];

        Object.values(newStaff).forEach((staff) => {
          newRow.push(
            Object.entries(newData[key][staff.name] || {})
              .map(([type, count]) => {
                return `${type}: ${count}`;
              })
              .join(", ")
          );
        });

        result.push(newRow);
      });

      this.itemData = result;
      // this.itemExcel = result
      console.table(result);

      // change data into the format
      let g, h, i;
      for (g = 1; g < result.length; g++) {
        for (h = 1; h < result[g].length; h++) {
          if (result[g][h] != "") {
            let res = result[g][h].split(",");
            for (i = 0; i < res.length; i++) {
              let dataSum = res[i].split(": ");
              res[i] = dataSum[0] + "= <b>" + dataSum[1] + "</b>,";
            }

            let finalText =
              "<ul><li>" + res[0] + "</li><li>" + res[1] + "</li></ul>";
            result[g][h] = finalText;

            // let dataSum = res[1]+"= <b>"
          } else {
            result[g][h] =
              "<ul><li>Op= <b>0</b>,</li><li> An= <b>0</b></li></ul>";
          }
        }
      }

      // show text data empty in table
      if (this.itemData.length <= 1) {
        this.nullMessage = true;
      }

      console.log(this.itemData.length,this.nullMessage,"ITEEMMSSSSS NIHHHHH");

      this.excel();
    },
    async excel() {
      let filterParams = `?month=${this.month}&year=${this.year}`;

      let response = await module.get(
        "medical-action-monthly",
        `${filterParams}`
      );

      this.items = response;

      const actions = response;

      let newData = {};
      let newStaff = {};
      let result = [];

      // pengumpulan data
      actions.forEach((action, index) => {
        action.action_type_display.forEach((actionDisplay) => {
          if (!newData[actionDisplay.name]) {
            newData[actionDisplay.name] = {};
          }

          action.operator_display.forEach((Op) => {
            if (!newData[actionDisplay.name][Op.name]) {
              newData[actionDisplay.name][Op.name] = {
                Op: 0,
                An: 0,
              };
            }

            newStaff[Op.id] = Op;
            newData[actionDisplay.name][Op.name].Op += 1;
          });

          action.anesthetist_display.forEach((An) => {
            if (!newData[actionDisplay.name][An.name]) {
              newData[actionDisplay.name][An.name] = {
                Op: 0,
                An: 0,
              };
            }

            newStaff[An.id] = An;
            newData[actionDisplay.name][An.name].An += 1;
          });

          // action.nurse_action_display.forEach((P) => {
          //   if (!newData[actionDisplay.name][P.name]) {
          //     newData[actionDisplay.name][P.name] = {
          //       A: 0,
          //       O: 0,
          //       P: 0
          //     }
          //   }

          //   newStaff[P.id] = P
          //   newData[actionDisplay.name][P.name].P += 1
          // })
        });
      });

      // bagian nampilin data ke table
      result.push([
        "Tindakan",
        ...Object.values(newStaff).map((staff) => staff.name),
      ]);

      Object.keys(newData).forEach((key) => {
        let newRow = [key];

        Object.values(newStaff).forEach((staff) => {
          newRow.push(
            Object.entries(newData[key][staff.name] || {})
              .map(([type, count]) => {
                return `${type}: ${count}`;
              })
              .join(", ")
          );
        });

        result.push(newRow);
      });

      this.itemExcel = result;
      // this.itemExcel = result
      console.table(result);

      // change data into the format
      let g, h, i;
      for (g = 1; g < this.itemExcel.length; g++) {
        for (h = 1; h < this.itemExcel[g].length; h++) {
          if (this.itemExcel[g][h] != "") {
            let res = this.itemExcel[g][h].split(",");
            for (i = 0; i < res.length; i++) {
              let dataSum = res[i].split(": ");
              res[i] = dataSum[0] + "= " + dataSum[1] + ",";
            }

            let finalText = "" + res[0] + "" + res[1] + "";
            this.itemExcel[g][h] = finalText;

            // let dataSum = res[1]+"= "
          } else {
            this.itemExcel[g][h] = "Op= 0, An= 0";
          }
        }
      }

      
    },

    // setCheckedItem() {
    //   let found
    //   this.items.forEach(value => {
    //     found = this.selectedItem.find(x => x.id == value.id)
    //     if (typeof found === 'undefined') {
    //       value.checked = false
    //     } else {
    //       value.checked = true
    //     }
    //   })
    // },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {},

    hasStartedGeneration() {},

    hasGenerated($event) {},
  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem();
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.getSetting();

    this.$root.$on("paginateDoctorRecapt", (m, y) => {
      this.month = m;
      this.year = y;

      console.log(this.month ,this.year,"paginateDoctorRecapt");
      this.pagination();
    });

    // this.setActiveButton()
  },
};
</script>

<style>
.text-medium {
  font-size: 15px;
}
</style>